<template>
  <BaseLayout title="我的订单">
    <van-pull-refresh v-model="state.refreshing" @refresh="onRefresh">

      <van-list class="list" v-model:loading="state.loading" :finished="state.finished" loading-text="正在请求数据 ..." finished-text="没有更多了" :immediate-check="false" @load="onLoad">
        
        <van-row class="block" align="center" v-for="(item,index) in state.list" :key="index">
          <van-col span="24" class="dt1"><van-tag color="#CCC">{{item.dt_create}}</van-tag></van-col>
          <van-col span="18" class="title">{{item.remark}}</van-col>
          <van-col span="6"  class="status" :style="{color:item.color}">{{item.status}}</van-col>
          <van-col span="18" class="detail">{{'单价：' + item.price + ' U&nbsp;，&nbsp;数量：' + item.num}}</van-col>
          <van-col span="6"  class="amount">{{item.fee + ' U'}}</van-col>
          <van-col span="24" class="extra">{{'付款时间：' + item.dt_pay}}</van-col>
        </van-row>

      </van-list>

    </van-pull-refresh>
  </BaseLayout>
</template>

<style scoped>
.list {
  min-height: 500px;
  padding: 10px;
  background: #F9F9F9;
  border-top: solid 1px #EEE;
}
.block {
  margin-top: 45px;
  padding: 0 10px 10px 10px;
  background: #FFF;
  border-radius: 10px;
  border: solid 1px #EEE;
  font-size: 12px;
}
.dt1 {
  position: relative;
  top: -25px;
  text-align: center;
}
.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
}
.status {
  text-align: right;
}
.detail {
  padding:12px 0;
}
.amount {
  padding:12px 0;
  font-size: 14px;
  font-weight: 600;
  text-align: right;
}
.extra {
  padding-bottom: 10px;
}

</style>

<script>
import {reactive} from 'vue'
import BaseLayout from '@/components/BaseLayout.vue'

export default {
  components: {BaseLayout},

  setup() {
    const state = reactive({
      refreshing: false,
      loading: false,
      finished: false,
      list: [],
      offset: 0,
      limit : 20
    });
    return { 
      state
    };
  },

  mounted(){
    this.onRefresh();
  },

  methods: {
    onRefresh() {
      this.state.loading = true;
      this.state.finished = false;
      this.state.offset = 0;
      this.state.list = [];
      this.onLoad();
    },
    onLoad() {
      this.$http.get("/trade/orderlist?offset=" + this.state.offset + "&limit=" + this.state.limit).then((data) => {
        this.state.refreshing = false;
        if (data.length == 0)
          return this.state.finished = true;
        this.state.loading = false;
        this.state.offset += data.length;

        for (let i in data){
          let item = data[i];
          this.state.list.push({...item, ...{
            status: ["未付款", "已付款", "交易成功", "交易失败"][item.status],
            color: ["#999", "#00C", "#0C0", "#C00"][item.status],
          }});
        }
      });
    },
  }
}
</script>
